"use client";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../lib/state/store";
import {
  convertMenuItems2FlatCategories,
  translateCategories,
} from "../lib/util/api.data.helper";
import Link from "next/link";
import ScrollableList from "./scrollable.list";
import PopoverCategories from "./popover.categories";
import { Button } from "antd";
import {
  CaretDownOutlined,
  GoldOutlined,
  MenuOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { StaticLinks } from "../lib/route/link";
import { useMediaQueryList } from "../lib/hooks/media.query";

function HeaderLinks() {
  const { isMobile } = useMediaQueryList();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const categories = useTypedSelector((state) => state.categories.categories);
  const flatCategoriesForNavbar = useTypedSelector(
    (state) => state.categories.flatCategoriesForNavbar || []
  );
  const categoriesTrans = useTypedSelector(
    (state) => state.categories.categoriesTrans
  );
  const { translatedCategoriesAll, categoriesToShow } = useMemo(() => {
    const retArray = convertMenuItems2FlatCategories(flatCategoriesForNavbar);
    return {
      categoriesToShow: translateCategories(
        retArray,
        categoriesTrans,
        language
      ),
      translatedCategoriesAll: translateCategories(
        categories,
        categoriesTrans,
        language
      ),
    };
  }, [categories, language, categoriesTrans, flatCategoriesForNavbar]);

  const [visible, setVisible] = useState(false);
  const [activeKey, setActiveKey] = useState(
    translatedCategoriesAll?.[0]?.key || ""
  );
  const hasChildren = (item: MenuItem) =>
    !!item.children?.length && item.children?.length > 0;

  // 渲染子菜单
  const renderChildren = (level: number, children?: MenuItem[]) => {
    return (
      <>
        {children?.map((child) => (
          <div
            key={child.key}
            // className={`col-span-1 ${level === 2 ? "pb-6" : "pt-1"}`}
            className={`flex ${isMobile ? "flex-wrap" : "flex-nowrap"} gap-6 ${
              level === 2 ? "pb-4" : ""
            }`}
          >
            <div
              className="pb-1"
              style={{
                width: level === 2 ? (isMobile ? "100%" : "240px") : "auto",
                flexShrink: 0,
              }}
            >
              <Link
                href={
                  !!child.collectionId
                    ? `${StaticLinks.SearchCollection}${child.collectionId}`
                    : !!child.externalId
                    ? `${StaticLinks.Category}${child.externalId}`
                    : "#"
                }
                onClick={() => {
                  (child.externalId || child.collectionId) && setVisible(false);
                }}
              >
                <span className={level === 2 ? "font-bold" : ""}>
                  {child.label}
                </span>
              </Link>
            </div>
            <div className="flex flex-wrap gap-4">
              {child.children && renderChildren(3, child.children)}
            </div>
          </div>
        ))}
      </>
    );
  };

  const menuData = translatedCategoriesAll;
  const renderMenu = () => {
    const activeItem = menuData.find((item) => item.key === activeKey);

    return (
      <div
        className="flex gap-10 thin-scrollbar pb-1 "
        style={{
          overflowX: "hidden",
          paddingLeft: isMobile ? "0px" : "8px",
          maxHeight: isMobile ? "50vh" : "auto",
        }}
      >
        <div
          className={`w-40 min-w-${isMobile ? 16 : 44} relative pt-${
            isMobile ? 0 : 1
          }}`}
        >
          <div className="">
            {menuData.map((item) => (
              <div
                key={item.key}
                className={`flex flex-row flex-nowrap gap-6 pb-2 ${
                  item.key === activeKey ? "text-primary" : ""
                }`}
                onMouseEnter={() => setActiveKey(item.key)}
              >
                <Link
                  href={
                    !!item.collectionId
                      ? `${StaticLinks.SearchCollection}${item.collectionId}`
                      : !!item.externalId
                      ? `${StaticLinks.Category}${item.externalId}`
                      : `#`
                  }
                  className="flex-grow"
                  onClick={(e) => {
                    if (isMobile) {
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }}
                >
                  <span>{item.label}</span>
                </Link>
                {hasChildren(item) && (
                  <RightOutlined style={{ fontSize: "12px" }} />
                )}
              </div>
            ))}
          </div>
        </div>
        <div
          className="overflow-auto flex-grow thin-scrollbar pb-1"
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: isMobile ? "2000px" : "500px",
          }}
        >
          {activeItem && renderChildren(2, activeItem.children)}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full">
      <div
        className="flex flex-row items-center space-x-4 p-0 h-10 whitespace-nowrap w-full category-icon-wrapper overflow-hidden"
        style={{
          marginBottom: isMobile ? "16px" : "0px",
          marginRight: isMobile ? "16px" : "0px",
        }}
      >
        {categories?.[0]?.key && (
          <Button
            type={"text"}
            onMouseEnter={() => setVisible(true)}
            onClick={() => setVisible(!visible)}
            style={{
              width: isMobile ? "auto" : "140px",
            }}
            icon={
              <div className="category-icon">
                <span className={visible ? "line-1" : ""}></span>
                <span className={visible ? "line-2" : ""}></span>
                <span className={visible ? "line-3" : ""}></span>
              </div>
            }
          >
            {!isMobile && <span className="text-black">{t("categories")}</span>}
          </Button>
        )}
        <div className="block min-w-0" onMouseEnter={() => setVisible(false)}>
          <ScrollableList items={categoriesToShow} />
        </div>
      </div>
      {visible && (
        <div
          className={`py-4 mb-4 w-full z-10 bg-white ml-${isMobile ? 1 : 7}`}
          onMouseLeave={() => setVisible(false)}
        >
          {renderMenu()}
        </div>
      )}
    </div>
  );
}

export default HeaderLinks;
