"use client";

import React, { Children, useEffect, useMemo, useState } from "react";
import {
  Input,
  Button,
  Tag,
  Space,
  Dropdown,
  Menu,
  Upload,
  message,
} from "antd";
import { SearchOutlined, CameraOutlined } from "@ant-design/icons";
import "./search.bar.css";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import Link from "next/link";
import {
  generateSearchParams,
  OfferSearchParamsKeyEnum,
} from "../lib/search/client";
import { useRouter, useSearchParams } from "next/navigation";
import { StaticLinks } from "../lib/route/link";
import { useTypedSelector } from "../lib/state/store";
import { searchApi } from "../lib/api/search";
import SearchHistory from "./search.history";
import { offerApi } from "../lib/api/offer";
import { useMediaQueryList } from "../lib/hooks/media.query";

const { Search } = Input;

const SearchBar: React.FC = () => {
  const { isMobile } = useMediaQueryList();
  const search = useSearchParams();
  const router = useRouter();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [keyword, setKeyword] = useState<string>("");
  const [focus, setFocus] = useState(false);
  const [visible, setVisible] = useState(false);

  const [topSearches, setTopSearches] = useState<string[]>([]);
  const [recentSearches, setRecentSearches] = useState<string[]>([]);

  const categories = useTypedSelector((state) => state.categories.categories);
  const categoriesTrans = useTypedSelector(
    (state) => state.categories.categoriesTrans
  );

  const userId = useTypedSelector((state) => state.user.user?.userId);

  // TODO 更新hot searches
  const hotSearches = useMemo(() => {
    return categories.slice(10, 13).map((w) => {
      return {
        label:
          categoriesTrans.find(
            (x) => `${x.categoryId}` === w.key && x.lang === language
          )?.description || w.label,
        key: w.key,
      };
    });
  }, [categories, categoriesTrans, language]);

  const recordSearch = async (searchTerm: string) => {
    await searchApi.recordSearch({ searchTerm, userId });
  };

  // 查询按钮，将查询参数通过searchparam传给search页面
  const handleSearch = (value: string, event: any, info?: any) => {
    if (info?.source === "input") {
      // 先往后台记录查询历史记录
      recordSearch(value);

      const search = generateSearchParams({
        [OfferSearchParamsKeyEnum.subject]: value,
      });
      router.push(`${StaticLinks.Search}?${search}`);
    }
  };

  // 从url search中获取subject值
  useEffect(() => {
    setKeyword(search.get(OfferSearchParamsKeyEnum.subject) || "");
  }, [search]);

  useEffect(() => {
    if (visible) {
      searchApi.getTop10Search().then((res) => {
        setTopSearches(res.data?.map((w) => w.searchTerm) || []);
      });
      searchApi.getRecentSearch(userId).then((res) => {
        setRecentSearches(res.data?.map((w) => w.searchTerm) || []);
      });
    }
  }, [visible]);

  // TODO 图片上传的API调用有问题，目前无法支持自定义图片上传
  const handleImageUpload = (file: any) => {
    const isLt300K = file.size / 1024 < 300;
    if (!isLt300K) {
      message.error(t("image_search_file_size_over_limit"));
      return Upload.LIST_IGNORE;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      let base64 = e.target.result;
      base64 = base64.replace("data:image/jpeg;base64,", "");
      offerApi.productImageUpload({ imageBase64: base64 }).then((res) => {
        const imageId = res.data;
        if (imageId) {
          router.push(`${StaticLinks.SearchImageUpload}${imageId}`);
        }
      });
    };
    reader.onerror = (e) => {
      message.error(t("image_search_file_read_fail"));
    };
    reader.readAsDataURL(file);
    return Upload.LIST_IGNORE;
  };

  return (
    <div className="search-bar space-x-4 w-full">
      {!isMobile && (
        <div className="logo flex-shrink-0">
          <Link href="/" className="a-nohover">
            <Image
              src={isMobile ? "/logo-small.png" : "/logo.png"}
              alt="Logo"
              width="100"
              height="23"
              style={{
                width: isMobile ? "40px" : "100px",
              }}
            />
          </Link>
        </div>
      )}
      <div className="search-area flex-grow border-1 relative">
        <Dropdown
          dropdownRender={() => (
            <div className="bg-white border border-primary-100">
              <SearchHistory
                topSearches={topSearches}
                recentSearches={recentSearches}
              />
            </div>
          )}
          open={visible}
          placement="bottomLeft"
        >
          <Search
            placeholder={t("enterSearchWords")}
            enterButton={
              <Button type="primary" style={{ height: "50px" }}>
                {t("search")}
              </Button>
            }
            prefix={
              <SearchOutlined
                style={{ fontSize: "20px" }}
                className="text-dark"
              />
            }
            suffix={
              !focus && (
                <Space
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {/* {hotSearches.map((item) => (
                    <Link
                      href={`${StaticLinks.Category}${item.key}`}
                      key={item.key}
                      onClick={(e) => {
                        // 避免影响背后的输入框点击事件
                        e.stopPropagation();
                      }}
                    >
                      <Button
                        className="rounded-xs"
                        style={{
                          paddingTop: 6,
                          paddingBottom: 6,
                          maxWidth: 200,
                        }}
                        title={item.label}
                      >
                        <span className="fix-to-width">{item.label}</span>
                      </Button>
                    </Link>
                  ))} */}
                  <Link href={StaticLinks.TopRank}>
                    <Image
                      src="/fire.svg"
                      width="24"
                      height="24"
                      alt="hot"
                      title={t("view_search_rank")}
                    ></Image>
                  </Link>
                  <Upload
                    accept="image/jpeg"
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                  >
                    <Button
                      icon={<CameraOutlined />}
                      title={t("search_by_image")}
                    />
                  </Upload>
                </Space>
              )
            }
            style={{ height: "48px", minWidth: "300px" }}
            className="flex-grow"
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
            onSearch={handleSearch}
            onFocus={() => {
              setFocus(true);
              setVisible(true);
            }}
            onBlur={() => {
              // 这里使用延迟关闭，可以让链接点击事件有机会处理
              setTimeout(() => {
                setFocus(false);
                setVisible(false);
              }, 300);
            }}
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default SearchBar;
