import { useSearchParams } from "next/navigation";

export const generateSearchParams = (offer: Partial<OfferType>): string => {
  const params = new URLSearchParams();
  Object.keys(offer).forEach((key) => {
    const value = offer[key as keyof OfferType];
    // 即使value为空，也添加进去，可以覆盖之前状态中的值
    params.append(key, String(value));
  });
  return params.toString();
};

export const OfferSearchParamsKeyEnum = {
  subject: "subject",
  categoryId: "categoryId",
  soldOut: "soldOut",
  tradeScore: "tradeScore",
  minOrderQuantity: "minOrderQuantity",
  minPrice: "minPrice",
  maxPrice: "maxPrice",
  isOnePsale: "isOnePsale",
  isSupportMix: "isSupportMix",
  isOnePsaleFreePostage: "isOnePsaleFreePostage",
  noReason7DReturn: "noReason7DReturn",
  yx1688: "yx1688",
  isQqyx: "isQqyx",
  isPartner: "isPartner",
};

export const useOfferFromUrl = (): OfferType | null => {
  const searchParams = useSearchParams();
  if (!searchParams) return null;

  const offer: Partial<OfferType> = {};

  searchParams.forEach((value, key) => {
    if (value === "") {
      // 这里避免转换为number 0
      offer[key as "subject"] = "";
    } else if (value === "true" || value === "false") {
      // as 'status'只是为了明确key是boolean类型，下同
      offer[key as "status"] = value === "true";
    } else if (!isNaN(Number(value))) {
      offer[key as "soldOut"] = Number(value);
    } else {
      offer[key as "subject"] = value;
    }
  });

  return offer as OfferType;
};
