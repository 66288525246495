import React, { useRef, useState, useEffect } from "react";
import { Button, Skeleton } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Link from "next/link";
import { StaticLinks } from "../lib/route/link";
import { useMediaQueryList } from "../lib/hooks/media.query";

interface ScrollableListProps {
  items: {
    key: string;
    label: string;
    collectionId?: string;
  }[];
}

const ScrollableList: React.FC<ScrollableListProps> = ({ items }) => {
  const { isMobile } = useMediaQueryList();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState<boolean>(false);
  const [isRightArrowVisible, setIsRightArrowVisible] = useState<boolean>(true);

  const updateArrows = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
      setIsLeftArrowVisible(scrollLeft > 0);
      // scrollWidth-5是为了避免计算误差
      setIsRightArrowVisible(scrollLeft + clientWidth < scrollWidth - 5);
    }
  };

  const scrollRequired = scrollContainerRef.current
    ? scrollContainerRef.current.scrollWidth >
      scrollContainerRef.current.clientWidth
    : false;

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -500, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 500, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const observer = new ResizeObserver(() => {
        updateArrows();
      });
      observer.observe(scrollContainer);
      scrollContainer.addEventListener("scroll", updateArrows);
      updateArrows();

      return () => {
        observer.unobserve(scrollContainer);
        scrollContainer.removeEventListener("scroll", updateArrows);
      };
    }
  }, []);

  return (
    <div className="relative flex-grow flex flex-nowrap gap-2 justify-center">
      <div
        className="flex whitespace-nowrap space-x-4 items-center flex-glow"
        style={{
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          overflowX: "auto",
          overflowY: "hidden",
          paddingRight: scrollRequired ? "auto" : "4px",
        }}
        ref={scrollContainerRef}
      >
        {items.map((item) => (
          <Link
            key={item.key}
            href={
              !!item.collectionId
                ? `${StaticLinks.SearchCollection}${item.collectionId}`
                : `${StaticLinks.Category}${item.key}`
            }
          >
            {item.label}
          </Link>
        ))}
        {items.length <= 0 &&
          Array(10)
            .fill(0)
            .map((_, index) => <Skeleton.Input active key={index} />)}
      </div>
      {!isMobile && scrollRequired && (
        <Button
          type="text"
          icon={<LeftOutlined style={{ fontSize: "14px" }} />}
          onClick={scrollLeft}
          disabled={!isLeftArrowVisible}
          className="px-1"
        ></Button>
      )}
      {!isMobile && scrollRequired && (
        <Button
          type="text"
          icon={<RightOutlined style={{ fontSize: "14px" }} />}
          onClick={scrollRight}
          disabled={!isRightArrowVisible}
          className="px-1"
        ></Button>
      )}
    </div>
  );
};

export default ScrollableList;
