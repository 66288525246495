import { useTranslation } from "react-i18next";
import { Dropdown, MenuProps, Skeleton } from "antd";
import { useTypedSelector } from "../lib/state/store";
import { DownOutlined, GlobalOutlined } from "@ant-design/icons";
import { UICountryFlag } from "../lib/language/lang.config";
import { useMediaQueryList } from "../lib/hooks/media.query";
import Image from "next/image";

function LanguageSwitcher() {
  const { isMobile } = useMediaQueryList();
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const languages = useTypedSelector((state) => state.lang).languages;
  const selectedLanguage = languages.find(
    (w) => w.lang === i18n.language
  )?.name;

  const items: MenuProps["items"] = languages
    .filter((l) => l.status === "1")
    .sort((a, b) => (a.lang < b.lang ? 1 : -1))
    .map((language) => ({
      key: language.lang,
      label: (
        <a
          target="self"
          rel="noopener noreferrer"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            changeLanguage(language.lang);
          }}
          className="a-nohover"
        >
          {language.name}
        </a>
      ),
      icon: UICountryFlag[language.lang] ? (
        <Image
          src={`/${UICountryFlag[language.lang]}.svg`}
          width="20"
          height="40"
          alt={UICountryFlag[language.lang]}
        />
      ) : undefined,
    }));

  return languages && languages.length == 0 ? (
    <Skeleton.Input active />
  ) : (
    <Dropdown menu={{ items }}>
      <a
        onClick={(e) => e.preventDefault()}
        className="pointer flex items-center justify-center space-x-1 cursor-pointer font-bold a-nohover"
      >
        <GlobalOutlined style={{ fontSize: "20px" }} />
        {!isMobile && (
          <span className="flex items-center">{selectedLanguage}</span>
        )}
        {!isMobile && (
          <span className="flex items-center">
            <DownOutlined style={{ fontSize: "12px", fontWeight: "bolder" }} />
          </span>
        )}
      </a>
    </Dropdown>
  );
}

export default LanguageSwitcher;
