import { Layout } from "antd";
import "./header.css";
import SearchBar from "./search.bar";
import TopNav from "./header.topnav";
import HeaderLinks from "./header.links";
import { Constants } from "../lib/constant";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { useMediaQueryList } from "../lib/hooks/media.query";

function Header() {
  const { isMobile } = useMediaQueryList();
  const lastScrollTop = useRef<number>(0);
  const stickyRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (stickyRef.current) {
      const currentScrollTop =
        window.scrollY || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop.current && currentScrollTop > 40) {
        stickyRef.current.style.display = "none";
        stickyRef.current.style.top = "0px";
        stickyRef.current.style.position = "fixed";
      } else {
        stickyRef.current.style.display = "flex";
        stickyRef.current.style.top = "0px";
        stickyRef.current.style.position = "auto";
      }

      lastScrollTop.current = currentScrollTop;
    }
  };

  useEffect(() => {
    // 避免频繁触发滚动事件导致反复刷新
    const handleAddEventScroll = () => {
      window.addEventListener("scroll", handleScroll);
    };
    handleAddEventScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    // 这个div是占位符，高度h-24和header一致，避免header固定到顶部之后出现滚动条往上滚
    <div className="h-48 lg:h-24">
      <header
        ref={stickyRef}
        // main-top-header 用来定位设置sticky元素的top值
        id="main-top-header"
        className={`w-full p-0 bg-transparent border-b-2 border-grey flex flex-col h-24`}
        style={{
          zIndex: Constants.ZIndex.STICKY,
        }}
      >
        <div className="w-full flex justify-center bg-white">
          <div
            className="flex flex-grow justify-center items-center py-2 px-2 lg:px-10 bg-white max-w-screen-xl1400"
            style={{ flexWrap: isMobile ? "wrap" : "nowrap" }}
          >
            {!!isMobile && (
              <div className="px-4 flex flex-1 space-x-3 whitespace-nowrap items-center justify-center pt-2 pb-8">
                <TopNav />
              </div>
            )}
            <SearchBar />
            {!isMobile && (
              <div className="ml-8 flex flex-1 space-x-3 whitespace-nowrap items-center">
                <TopNav />
              </div>
            )}
          </div>
        </div>
        <div className="w-full flex justify-center bg-white pt-2">
          <div className="w-full px-6 lg:px-10 flex justify-center">
            <HeaderLinks />
          </div>
        </div>
      </header>
    </div>
  );
}

export function useVisibleHeader() {
  const [headerVisible, setHeaderVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector("#main-top-header");
      if (element) {
        const style = window.getComputedStyle(element);
        setHeaderVisible(style.display !== "none");
      }
    };
    // 避免频繁触发滚动事件导致反复刷新
    const handleAddEventScroll = () => {
      window.addEventListener("scroll", handleScroll);
    };
    handleAddEventScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return headerVisible;
}

export default React.memo(Header);
