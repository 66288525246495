import { useState, useEffect, useCallback } from "react";
import { useWebSocket } from "./websocket.context";
import { MessageParser } from "./message.parser";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../state/store";
import { setMessage } from "../state/websocket.slice";

export const useWebSocketMessages = () => {
  const { wsService } = useWebSocket();
  const dispatch = useDispatch();
  const userId = useTypedSelector((s) => s.user.user?.userId);
  const messageFromStore = useTypedSelector((s) => s.websocket.message);

  useEffect(() => {
    if (wsService) {
      const handleMessage = (message: string) => {
        try {
          const parsedMessage = MessageParser.parse(
            JSON.parse(message)
          ).serialize();
          dispatch(setMessage(parsedMessage));
        } catch (e) {
          console.error(e);
        }
      };

      wsService.addListener(handleMessage);
      return () => wsService.removeListener(handleMessage);
    }
    // 当切换用户时，重新添加消息监听函数
  }, [dispatch, wsService, userId]);

  const sendMessage = useCallback(
    (message: string) => {
      if (wsService) {
        wsService.sendMessage(message);
      }
    },
    [wsService]
  );

  return { message: messageFromStore, sendMessage };
};
